import { Group } from './group'
import { User } from './user'

export class Role {
  public static createFromJson (json: any): Role {
    const group = json.group ? Group.createFromJson(json.group) : null
    return new Role(json.id, json.uid, json.gid, json.admin, json.user, group)
  }

  constructor (
    public id: number,
    public uid: string,
    public gid: string,
    public admin: boolean,
    public user: User,
    public group: Group | null,
  ) {}
}

export type RoleInput = {
  gid: string
  uid: string
  admin: boolean
}
