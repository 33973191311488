import { Organization } from './organization'
import { Role } from './role'

export class User {
  public static createFromJson (json: any): User {
    const organization = Organization.createFromJson(json.organization ?? {})
    const roles = (json.roles ?? []).map((it: any) => Role.createFromJson(it))
    return new User(
      json.id,
      json.name,
      json.picture,
      json.email,
      json.orgId,
      json.staffId,
      json.smartPhrId ?? null,
      json.admin,
      json.commute ?? null,
      json.defaultWorkStartTime,
      json.activateCode ?? null,
      json.activateRequestAt ?? null,
      json.activateConfirmAt ?? null,
      organization,
      roles,
    )
  }

  constructor (
    public id: string,
    public name: string,
    public picture: string,
    public email: string,
    public orgId: string,
    public staffId: string,
    public smartPhrId: string | null,
    public admin: boolean,
    public commute: number | null,
    public defaultWorkStartTime: string,
    public activateCode: number | null,
    public activateConfirmAt: Date | null,
    public activateRequestAt: Date | null,
    public organization: Organization,
    public roles: Role[],
  ) {}
}

export type UserInput = {
  name: string
  email: string
  staffId: string
  admin: boolean
}

export type WorkDaysUserInput = {
  commute?: number | null
  defaultWorkStartTime?: string
}

export type WorkDaysInput = {
  uid: string
  commute: number | null
  defaultWorkStartTime: string
  add: Date[]
  remove: Date[]
}

export class OrganizationProfile {
  public static createFromJson (json: any): OrganizationProfile {
    return new OrganizationProfile(
      json.id,
      json.name,
      json.email,
      json.orgId,
      json.staffId ?? null,
      json.smartPhrId ?? null,
      json.state,
    )
  }

  constructor (
    public id: string,
    public name: string,
    public email: string,
    public orgId: string,
    public staffId: string | null,
    public smartPhrId: string | null,
    public state: number,
  ) {}
}

